/* eslint-disable */
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { HomeValue } from "../../../ballpark/data/home_value";
import {
  MarketingChannel,
  marketingChannelFromJSON,
  marketingChannelToJSON,
  Partnership,
  partnershipFromJSON,
  partnershipToJSON,
  Segment,
  segmentFromJSON,
  segmentToJSON,
  Timeline,
  timelineFromJSON,
  timelineToJSON,
} from "../../common/common";
import { Answers } from "./answers";
import { Attributes } from "./attributes";
import { PartnershipReferral } from "./partnership_referral";
import { ProductOffering } from "./product_offering";

export const protobufPackage = "od_protos.sell.reception.data";

/**
 * A SellerInput is a collection of information from a user who is interested in
 * selling their home.
 * Next ID: 47
 */
export interface SellerInput {
  /** External identifier for the seller input. */
  uuid: string;
  /** Casa address that the seller input is for. */
  addressUuid: string;
  /**
   * Seller flow that the seller went through.
   *
   * @deprecated
   */
  sellerFlowUuid: string;
  /**
   * The channel the seller came in from, used to determine the overall experience
   * the seller will go through.
   *
   * @deprecated
   */
  channel: SellerInput_Channel;
  /**
   * JSON answers that the seller provided (e.g. home details, sale timeline).
   * Example: "{ \"home.bedrooms\": 3, \"seller.email\": \"test@test.com\" }"
   */
  answersJson: string;
  /** Answers that the seller provided in a well typed protobuf schema. */
  answers?:
    | Answers
    | undefined;
  /**
   * JSON prefilled answers shown to the seller based on Dwellings data.
   * Example: "{ \"home.bedrooms\": { \"value\": 3, \"source\": \"MLS\" } }"
   */
  answerPrefillsJson: string;
  /**
   * Prefilled answers shown to the seller based on Dwellings data.
   * Example: "{ \"home.bedrooms\": { \"value\": 3, \"source\": \"MLS\" } }"
   */
  answerPrefills?:
    | { [key: string]: any }
    | undefined;
  /** Time the seller input was created. */
  createdAt?:
    | Date
    | undefined;
  /** Time the seller input was last updated. */
  updatedAt?:
    | Date
    | undefined;
  /** Custard customer that initiated the seller input. */
  customerUuid: string;
  /** Last page the seller completed in the seller flow. */
  sellerFlowLastCompletedSlug: string;
  /** Whether the address was confirmed the be correct by the seller. */
  addressVerified: boolean;
  addressVerifiedStatus: SellerInput_AddressVerifiedStatus;
  /** Whether the home is listed on an MLS. */
  isListed: boolean;
  /** Whether the customer is a real estate agent. */
  isAgent: boolean;
  /**
   * Whether the home is a potential trade in or not.
   *
   * @deprecated
   */
  isTradeIn: boolean;
  /**
   * Whether the home is a potential trade in bundle (sell + buy + finance).
   *
   * @deprecated
   */
  isTradeInBundle: boolean;
  /**
   * Whether or not the user is in the new trade-in dashboard experience.
   *
   * @deprecated
   */
  isTradeInDashboard: boolean;
  /**
   * Whether the seller has verified themselves by selecting the right owner name.
   *
   * @deprecated
   */
  ownerVerified: boolean;
  /**
   * The home builder partnership the seller came in through, if any.
   *
   * @deprecated
   */
  partnershipName: string;
  /**
   * Whether the address has a bulk private home profile available.
   *
   * @deprecated
   */
  hasBulkValuation: boolean;
  /**
   * Home value estimate in cents for denial prompts in the seller flow.
   *
   * @deprecated
   */
  homeEstimateForDenials: number;
  /** Home details for the address. */
  homeDetails?:
    | SellerInput_HomeDetails
    | undefined;
  /** The marketing channel the seller came in from. */
  marketingChannel: MarketingChannel;
  /** The home builder partnership the seller came in through, if any. */
  partnership: Partnership;
  /** Seller's sale timeline. */
  timeline: Timeline;
  /** All the product offerings associated with this seller input. */
  productOfferings: ProductOffering[];
  /** Name of the market region the home is in, if any. */
  region: string;
  /** Home detail identifier for the home data store. */
  homeDetailId: string;
  /**
   * Whether the seller input is in the marketplace top-of-funnel experiment
   *
   * @deprecated
   */
  inMarketplaceTopOfFunnelExp: boolean;
  /**
   * A list of matched segments
   *
   * @deprecated
   */
  segments: Segment[];
  /** Experiment entity ID to be used with Optimizely */
  experimentEntityId: string;
  /** A list of partnership referrals. */
  partnershipReferrals: PartnershipReferral[];
  /** Extra per-seller-input data. */
  attributes?:
    | Attributes
    | undefined;
  /** HomeValue of this address */
  homeValue?:
    | HomeValue
    | undefined;
  /** When all the product offerings reach a terminal state (qualified or denied) */
  isCompletedAt?:
    | Date
    | undefined;
  /** Which version of the seller flow we will show the customer */
  sellerFlowName: string;
  /** A url to the property's details page */
  pdpUrl: string;
  /** Seller input data used for analytics */
  analyticsMetadata?:
    | SellerInput_AnalyticsMetadata
    | undefined;
  /** Whether the address is in eligible partnership service regions */
  isInPartnershipDenialServiceRegions: boolean;
  /** Identify the partner who should receive denial lead(redfin/mark_spain/parn) */
  denialPartnerIdentifier: string;
}

/**
 * The channel the seller came in from, used to determine the overall experience
 * the seller will go through.
 * Next ID: 19
 */
export enum SellerInput_Channel {
  /** UNSET_CHANNEL - DO NOT USE, this represents an unset enum value. */
  UNSET_CHANNEL = 0,
  /** DEFAULT - DEFAULT is the standard channel for incoming leads. */
  DEFAULT = 1,
  /** HOME_VALUE - HOME_VALUE is used for leads from the /home-value landing page. */
  HOME_VALUE = 2,
  /** SELLER_REQUEST_LISTED - SELLER_REQUEST_LISTED is used for seller requested leads on listed homes. */
  SELLER_REQUEST_LISTED = 3,
  /** AGENT_REQUEST - AGENT_REQUEST is used for agent requested leads. */
  AGENT_REQUEST = 4,
  /** AGENT_REQUEST_LISTED - AGENT_REQUEST_LISTED is used for agent requested leads on listed homes. */
  AGENT_REQUEST_LISTED = 5,
  /**
   * TRADE_IN - TRADE_IN is used for leads from the /trade-in landing page.
   *
   * @deprecated
   */
  TRADE_IN = 6,
  /** APP - APP is used for leads from the mobile app. */
  APP = 7,
  /**
   * CAKE_LANDING_PAGE - CAKE_LANDING_PAGE is for leads starting from the /tradeins landing page,
   * intended to reach the new customer dashboard.
   */
  CAKE_LANDING_PAGE = 8,
  /**
   * FLYWHEEL - FLYWHEEL is used for leads from the address entry in the app before a
   * buyer unlocks a home, as derived from project Flywheel
   */
  FLYWHEEL = 9,
  /** EMAIL_PROSPECTING - EMAIL_PROSPECTING is used for leads from the cold email prospecting experiment. */
  EMAIL_PROSPECTING = 10,
  /** OUTBOUND_OFFERS_LISTED - OUTBOUND_OFFERS_LISTED is used for 2020 Hackweek experiment of sending offers to listed homes */
  OUTBOUND_OFFERS_LISTED = 11,
  /**
   * HOME_RESERVE - HOME_RESERVE is used for Home reserve landing page
   *
   * @deprecated
   */
  HOME_RESERVE = 12,
  /** REIT_BULK_REQUEST - REIT_BULK_REQUEST is used for bulk acquisition requests from REITs */
  REIT_BULK_REQUEST = 13,
  /** PARTNER_API - PARTNER_API is used for seller inputs created via the partner API */
  PARTNER_API = 14,
  /** BUY_SELL_FLOW - BUY_SELL_FLOW is used for the combined buy and sell flow launched by Buyer in 2021 */
  BUY_SELL_FLOW = 15,
  REIT_BULK_REQUEST_LISTED = 16,
  /** OPENDOOR_BACKED_OFFER - OPENDOOR_BACKED_OFFER is used for bwod obo program */
  OPENDOOR_BACKED_OFFER = 17,
  /** EXCLUSIVE_OFFERS - EXCLUSIVE_OFFER is used for exclusive offers program */
  EXCLUSIVE_OFFERS = 18,
  UNRECOGNIZED = -1,
}

export function sellerInput_ChannelFromJSON(object: any): SellerInput_Channel {
  switch (object) {
    case 0:
    case "UNSET_CHANNEL":
      return SellerInput_Channel.UNSET_CHANNEL;
    case 1:
    case "DEFAULT":
      return SellerInput_Channel.DEFAULT;
    case 2:
    case "HOME_VALUE":
      return SellerInput_Channel.HOME_VALUE;
    case 3:
    case "SELLER_REQUEST_LISTED":
      return SellerInput_Channel.SELLER_REQUEST_LISTED;
    case 4:
    case "AGENT_REQUEST":
      return SellerInput_Channel.AGENT_REQUEST;
    case 5:
    case "AGENT_REQUEST_LISTED":
      return SellerInput_Channel.AGENT_REQUEST_LISTED;
    case 6:
    case "TRADE_IN":
      return SellerInput_Channel.TRADE_IN;
    case 7:
    case "APP":
      return SellerInput_Channel.APP;
    case 8:
    case "CAKE_LANDING_PAGE":
      return SellerInput_Channel.CAKE_LANDING_PAGE;
    case 9:
    case "FLYWHEEL":
      return SellerInput_Channel.FLYWHEEL;
    case 10:
    case "EMAIL_PROSPECTING":
      return SellerInput_Channel.EMAIL_PROSPECTING;
    case 11:
    case "OUTBOUND_OFFERS_LISTED":
      return SellerInput_Channel.OUTBOUND_OFFERS_LISTED;
    case 12:
    case "HOME_RESERVE":
      return SellerInput_Channel.HOME_RESERVE;
    case 13:
    case "REIT_BULK_REQUEST":
      return SellerInput_Channel.REIT_BULK_REQUEST;
    case 14:
    case "PARTNER_API":
      return SellerInput_Channel.PARTNER_API;
    case 15:
    case "BUY_SELL_FLOW":
      return SellerInput_Channel.BUY_SELL_FLOW;
    case 16:
    case "REIT_BULK_REQUEST_LISTED":
      return SellerInput_Channel.REIT_BULK_REQUEST_LISTED;
    case 17:
    case "OPENDOOR_BACKED_OFFER":
      return SellerInput_Channel.OPENDOOR_BACKED_OFFER;
    case 18:
    case "EXCLUSIVE_OFFERS":
      return SellerInput_Channel.EXCLUSIVE_OFFERS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellerInput_Channel.UNRECOGNIZED;
  }
}

export function sellerInput_ChannelToJSON(object: SellerInput_Channel): string {
  switch (object) {
    case SellerInput_Channel.UNSET_CHANNEL:
      return "UNSET_CHANNEL";
    case SellerInput_Channel.DEFAULT:
      return "DEFAULT";
    case SellerInput_Channel.HOME_VALUE:
      return "HOME_VALUE";
    case SellerInput_Channel.SELLER_REQUEST_LISTED:
      return "SELLER_REQUEST_LISTED";
    case SellerInput_Channel.AGENT_REQUEST:
      return "AGENT_REQUEST";
    case SellerInput_Channel.AGENT_REQUEST_LISTED:
      return "AGENT_REQUEST_LISTED";
    case SellerInput_Channel.TRADE_IN:
      return "TRADE_IN";
    case SellerInput_Channel.APP:
      return "APP";
    case SellerInput_Channel.CAKE_LANDING_PAGE:
      return "CAKE_LANDING_PAGE";
    case SellerInput_Channel.FLYWHEEL:
      return "FLYWHEEL";
    case SellerInput_Channel.EMAIL_PROSPECTING:
      return "EMAIL_PROSPECTING";
    case SellerInput_Channel.OUTBOUND_OFFERS_LISTED:
      return "OUTBOUND_OFFERS_LISTED";
    case SellerInput_Channel.HOME_RESERVE:
      return "HOME_RESERVE";
    case SellerInput_Channel.REIT_BULK_REQUEST:
      return "REIT_BULK_REQUEST";
    case SellerInput_Channel.PARTNER_API:
      return "PARTNER_API";
    case SellerInput_Channel.BUY_SELL_FLOW:
      return "BUY_SELL_FLOW";
    case SellerInput_Channel.REIT_BULK_REQUEST_LISTED:
      return "REIT_BULK_REQUEST_LISTED";
    case SellerInput_Channel.OPENDOOR_BACKED_OFFER:
      return "OPENDOOR_BACKED_OFFER";
    case SellerInput_Channel.EXCLUSIVE_OFFERS:
      return "EXCLUSIVE_OFFERS";
    case SellerInput_Channel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SellerInput_AddressVerifiedStatus {
  /** NOT_VERIFIED - default for new seller inputs */
  NOT_VERIFIED = 0,
  /** AUTO_VERIFIED - seller has implicitly confirmed their address, able to change their address */
  AUTO_VERIFIED = 1,
  /** CONFIRMED_VERIFIED - seller has explicitly confirmed their address or moved to a state where their address cannot be changed */
  CONFIRMED_VERIFIED = 2,
  UNRECOGNIZED = -1,
}

export function sellerInput_AddressVerifiedStatusFromJSON(object: any): SellerInput_AddressVerifiedStatus {
  switch (object) {
    case 0:
    case "NOT_VERIFIED":
      return SellerInput_AddressVerifiedStatus.NOT_VERIFIED;
    case 1:
    case "AUTO_VERIFIED":
      return SellerInput_AddressVerifiedStatus.AUTO_VERIFIED;
    case 2:
    case "CONFIRMED_VERIFIED":
      return SellerInput_AddressVerifiedStatus.CONFIRMED_VERIFIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellerInput_AddressVerifiedStatus.UNRECOGNIZED;
  }
}

export function sellerInput_AddressVerifiedStatusToJSON(object: SellerInput_AddressVerifiedStatus): string {
  switch (object) {
    case SellerInput_AddressVerifiedStatus.NOT_VERIFIED:
      return "NOT_VERIFIED";
    case SellerInput_AddressVerifiedStatus.AUTO_VERIFIED:
      return "AUTO_VERIFIED";
    case SellerInput_AddressVerifiedStatus.CONFIRMED_VERIFIED:
      return "CONFIRMED_VERIFIED";
    case SellerInput_AddressVerifiedStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Home details for a SellerInput address. */
export interface SellerInput_HomeDetails {
  /** Listing data for the home's most recent MLS listing. */
  lastListing?:
    | SellerInput_HomeDetails_ListingDetails
    | undefined;
  /** Physical properties of the home. */
  physicalDetails?:
    | SellerInput_HomeDetails_PhysicalDetails
    | undefined;
  /** Photos of the home provided by Dwellings from MLS. */
  photos: string[];
  /** Whether the address is near a flood zone. */
  nearFloodZone: boolean;
}

/** Listing data from an MLS listing. */
export interface SellerInput_HomeDetails_ListingDetails {
  /** MLS listing id for the listing. */
  listingId: string;
  /** Listing status for the listing. */
  listingStatus: string;
  /** Realtor name on the listing. */
  realtorName: string;
  /** Realtor email on the listing. */
  realtorEmail: string;
  /** List date of the listing */
  listDate: string;
  /** Current listing price. */
  listPriceCents: number;
  /** Photos for the listing. */
  listingPhotos: string[];
}

/** Next ID: 26 */
export interface SellerInput_HomeDetails_PhysicalDetails {
  ageRestrictedCommunity?: boolean | undefined;
  basement?: string | undefined;
  basementFinishedSqFt?: number | undefined;
  basementSqFt?: number | undefined;
  basementUnfinishedSqFt?: number | undefined;
  bathrooms?: number | undefined;
  bathroomsFull?: number | undefined;
  bathroomsHalf?: number | undefined;
  bedrooms?: number | undefined;
  carportSpaces?:
    | number
    | undefined;
  /** @deprecated */
  distanceToFloodZone?: number | undefined;
  dwellingStyle?: string | undefined;
  dwellingType?: string | undefined;
  exteriorStories?: number | undefined;
  garageSpaces?: number | undefined;
  hoaNames: string[];
  /** @deprecated */
  floodZone?: boolean | undefined;
  gatedCommunity?: boolean | undefined;
  pool?: boolean | undefined;
  primaryOwner?: string | undefined;
  lotSqFt?: number | undefined;
  sqFt?: number | undefined;
  yearBuilt?: number | undefined;
  kitchenApplianceType?: string | undefined;
  flooringMaterials: string[];
}

/** Next ID: 2 */
export interface SellerInput_AnalyticsMetadata {
  addressEntry?: SellerInput_AnalyticsMetadata_AddressEntry | undefined;
}

/** Next ID: 4 */
export enum SellerInput_AnalyticsMetadata_Product {
  UNKNOWN = 0,
  SELL_DIRECT = 1,
  OPENDOOR_COMPLETE = 2,
  OPENDOOR_EXCLUSIVES = 3,
  UNRECOGNIZED = -1,
}

export function sellerInput_AnalyticsMetadata_ProductFromJSON(object: any): SellerInput_AnalyticsMetadata_Product {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return SellerInput_AnalyticsMetadata_Product.UNKNOWN;
    case 1:
    case "SELL_DIRECT":
      return SellerInput_AnalyticsMetadata_Product.SELL_DIRECT;
    case 2:
    case "OPENDOOR_COMPLETE":
      return SellerInput_AnalyticsMetadata_Product.OPENDOOR_COMPLETE;
    case 3:
    case "OPENDOOR_EXCLUSIVES":
      return SellerInput_AnalyticsMetadata_Product.OPENDOOR_EXCLUSIVES;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SellerInput_AnalyticsMetadata_Product.UNRECOGNIZED;
  }
}

export function sellerInput_AnalyticsMetadata_ProductToJSON(object: SellerInput_AnalyticsMetadata_Product): string {
  switch (object) {
    case SellerInput_AnalyticsMetadata_Product.UNKNOWN:
      return "UNKNOWN";
    case SellerInput_AnalyticsMetadata_Product.SELL_DIRECT:
      return "SELL_DIRECT";
    case SellerInput_AnalyticsMetadata_Product.OPENDOOR_COMPLETE:
      return "OPENDOOR_COMPLETE";
    case SellerInput_AnalyticsMetadata_Product.OPENDOOR_EXCLUSIVES:
      return "OPENDOOR_EXCLUSIVES";
    case SellerInput_AnalyticsMetadata_Product.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SellerInput_AnalyticsMetadata_AddressEntry {
  product: SellerInput_AnalyticsMetadata_Product;
  url: string;
}

function createBaseSellerInput(): SellerInput {
  return {
    uuid: "",
    addressUuid: "",
    sellerFlowUuid: "",
    channel: 0,
    answersJson: "",
    answers: undefined,
    answerPrefillsJson: "",
    answerPrefills: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    customerUuid: "",
    sellerFlowLastCompletedSlug: "",
    addressVerified: false,
    addressVerifiedStatus: 0,
    isListed: false,
    isAgent: false,
    isTradeIn: false,
    isTradeInBundle: false,
    isTradeInDashboard: false,
    ownerVerified: false,
    partnershipName: "",
    hasBulkValuation: false,
    homeEstimateForDenials: 0,
    homeDetails: undefined,
    marketingChannel: 0,
    partnership: 0,
    timeline: 0,
    productOfferings: [],
    region: "",
    homeDetailId: "",
    inMarketplaceTopOfFunnelExp: false,
    segments: [],
    experimentEntityId: "",
    partnershipReferrals: [],
    attributes: undefined,
    homeValue: undefined,
    isCompletedAt: undefined,
    sellerFlowName: "",
    pdpUrl: "",
    analyticsMetadata: undefined,
    isInPartnershipDenialServiceRegions: false,
    denialPartnerIdentifier: "",
  };
}

export const SellerInput = {
  fromJSON(object: any): SellerInput {
    return {
      uuid: isSet(object.uuid) ? String(object.uuid) : "",
      addressUuid: isSet(object.addressUuid) ? String(object.addressUuid) : "",
      sellerFlowUuid: isSet(object.sellerFlowUuid) ? String(object.sellerFlowUuid) : "",
      channel: isSet(object.channel) ? sellerInput_ChannelFromJSON(object.channel) : 0,
      answersJson: isSet(object.answersJson) ? String(object.answersJson) : "",
      answers: isSet(object.answers) ? Answers.fromJSON(object.answers) : undefined,
      answerPrefillsJson: isSet(object.answerPrefillsJson) ? String(object.answerPrefillsJson) : "",
      answerPrefills: isObject(object.answerPrefills) ? object.answerPrefills : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : "",
      sellerFlowLastCompletedSlug: isSet(object.sellerFlowLastCompletedSlug)
        ? String(object.sellerFlowLastCompletedSlug)
        : "",
      addressVerified: isSet(object.addressVerified) ? Boolean(object.addressVerified) : false,
      addressVerifiedStatus: isSet(object.addressVerifiedStatus)
        ? sellerInput_AddressVerifiedStatusFromJSON(object.addressVerifiedStatus)
        : 0,
      isListed: isSet(object.isListed) ? Boolean(object.isListed) : false,
      isAgent: isSet(object.isAgent) ? Boolean(object.isAgent) : false,
      isTradeIn: isSet(object.isTradeIn) ? Boolean(object.isTradeIn) : false,
      isTradeInBundle: isSet(object.isTradeInBundle) ? Boolean(object.isTradeInBundle) : false,
      isTradeInDashboard: isSet(object.isTradeInDashboard) ? Boolean(object.isTradeInDashboard) : false,
      ownerVerified: isSet(object.ownerVerified) ? Boolean(object.ownerVerified) : false,
      partnershipName: isSet(object.partnershipName) ? String(object.partnershipName) : "",
      hasBulkValuation: isSet(object.hasBulkValuation) ? Boolean(object.hasBulkValuation) : false,
      homeEstimateForDenials: isSet(object.homeEstimateForDenials) ? Number(object.homeEstimateForDenials) : 0,
      homeDetails: isSet(object.homeDetails) ? SellerInput_HomeDetails.fromJSON(object.homeDetails) : undefined,
      marketingChannel: isSet(object.marketingChannel) ? marketingChannelFromJSON(object.marketingChannel) : 0,
      partnership: isSet(object.partnership) ? partnershipFromJSON(object.partnership) : 0,
      timeline: isSet(object.timeline) ? timelineFromJSON(object.timeline) : 0,
      productOfferings: Array.isArray(object?.productOfferings)
        ? object.productOfferings.map((e: any) => ProductOffering.fromJSON(e))
        : [],
      region: isSet(object.region) ? String(object.region) : "",
      homeDetailId: isSet(object.homeDetailId) ? String(object.homeDetailId) : "",
      inMarketplaceTopOfFunnelExp: isSet(object.inMarketplaceTopOfFunnelExp)
        ? Boolean(object.inMarketplaceTopOfFunnelExp)
        : false,
      segments: Array.isArray(object?.segments)
        ? object.segments.map((e: any) => segmentFromJSON(e))
        : [],
      experimentEntityId: isSet(object.experimentEntityId) ? String(object.experimentEntityId) : "",
      partnershipReferrals: Array.isArray(object?.partnershipReferrals)
        ? object.partnershipReferrals.map((e: any) => PartnershipReferral.fromJSON(e))
        : [],
      attributes: isSet(object.attributes) ? Attributes.fromJSON(object.attributes) : undefined,
      homeValue: isSet(object.homeValue) ? HomeValue.fromJSON(object.homeValue) : undefined,
      isCompletedAt: isSet(object.isCompletedAt) ? fromJsonTimestamp(object.isCompletedAt) : undefined,
      sellerFlowName: isSet(object.sellerFlowName) ? String(object.sellerFlowName) : "",
      pdpUrl: isSet(object.pdpUrl) ? String(object.pdpUrl) : "",
      analyticsMetadata: isSet(object.analyticsMetadata)
        ? SellerInput_AnalyticsMetadata.fromJSON(object.analyticsMetadata)
        : undefined,
      isInPartnershipDenialServiceRegions: isSet(object.isInPartnershipDenialServiceRegions)
        ? Boolean(object.isInPartnershipDenialServiceRegions)
        : false,
      denialPartnerIdentifier: isSet(object.denialPartnerIdentifier) ? String(object.denialPartnerIdentifier) : "",
    };
  },

  toJSON(message: SellerInput): unknown {
    const obj: any = {};
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.addressUuid !== "") {
      obj.addressUuid = message.addressUuid;
    }
    if (message.sellerFlowUuid !== "") {
      obj.sellerFlowUuid = message.sellerFlowUuid;
    }
    if (message.channel !== 0) {
      obj.channel = sellerInput_ChannelToJSON(message.channel);
    }
    if (message.answersJson !== "") {
      obj.answersJson = message.answersJson;
    }
    if (message.answers !== undefined) {
      obj.answers = Answers.toJSON(message.answers);
    }
    if (message.answerPrefillsJson !== "") {
      obj.answerPrefillsJson = message.answerPrefillsJson;
    }
    if (message.answerPrefills !== undefined) {
      obj.answerPrefills = message.answerPrefills;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    if (message.customerUuid !== "") {
      obj.customerUuid = message.customerUuid;
    }
    if (message.sellerFlowLastCompletedSlug !== "") {
      obj.sellerFlowLastCompletedSlug = message.sellerFlowLastCompletedSlug;
    }
    if (message.addressVerified === true) {
      obj.addressVerified = message.addressVerified;
    }
    if (message.addressVerifiedStatus !== 0) {
      obj.addressVerifiedStatus = sellerInput_AddressVerifiedStatusToJSON(message.addressVerifiedStatus);
    }
    if (message.isListed === true) {
      obj.isListed = message.isListed;
    }
    if (message.isAgent === true) {
      obj.isAgent = message.isAgent;
    }
    if (message.isTradeIn === true) {
      obj.isTradeIn = message.isTradeIn;
    }
    if (message.isTradeInBundle === true) {
      obj.isTradeInBundle = message.isTradeInBundle;
    }
    if (message.isTradeInDashboard === true) {
      obj.isTradeInDashboard = message.isTradeInDashboard;
    }
    if (message.ownerVerified === true) {
      obj.ownerVerified = message.ownerVerified;
    }
    if (message.partnershipName !== "") {
      obj.partnershipName = message.partnershipName;
    }
    if (message.hasBulkValuation === true) {
      obj.hasBulkValuation = message.hasBulkValuation;
    }
    if (message.homeEstimateForDenials !== 0) {
      obj.homeEstimateForDenials = Math.round(message.homeEstimateForDenials);
    }
    if (message.homeDetails !== undefined) {
      obj.homeDetails = SellerInput_HomeDetails.toJSON(message.homeDetails);
    }
    if (message.marketingChannel !== 0) {
      obj.marketingChannel = marketingChannelToJSON(message.marketingChannel);
    }
    if (message.partnership !== 0) {
      obj.partnership = partnershipToJSON(message.partnership);
    }
    if (message.timeline !== 0) {
      obj.timeline = timelineToJSON(message.timeline);
    }
    if (message.productOfferings?.length) {
      obj.productOfferings = message.productOfferings.map((e) => ProductOffering.toJSON(e));
    }
    if (message.region !== "") {
      obj.region = message.region;
    }
    if (message.homeDetailId !== "") {
      obj.homeDetailId = message.homeDetailId;
    }
    if (message.inMarketplaceTopOfFunnelExp === true) {
      obj.inMarketplaceTopOfFunnelExp = message.inMarketplaceTopOfFunnelExp;
    }
    if (message.segments?.length) {
      obj.segments = message.segments.map((e) => segmentToJSON(e));
    }
    if (message.experimentEntityId !== "") {
      obj.experimentEntityId = message.experimentEntityId;
    }
    if (message.partnershipReferrals?.length) {
      obj.partnershipReferrals = message.partnershipReferrals.map((e) => PartnershipReferral.toJSON(e));
    }
    if (message.attributes !== undefined) {
      obj.attributes = Attributes.toJSON(message.attributes);
    }
    if (message.homeValue !== undefined) {
      obj.homeValue = HomeValue.toJSON(message.homeValue);
    }
    if (message.isCompletedAt !== undefined) {
      obj.isCompletedAt = message.isCompletedAt.toISOString();
    }
    if (message.sellerFlowName !== "") {
      obj.sellerFlowName = message.sellerFlowName;
    }
    if (message.pdpUrl !== "") {
      obj.pdpUrl = message.pdpUrl;
    }
    if (message.analyticsMetadata !== undefined) {
      obj.analyticsMetadata = SellerInput_AnalyticsMetadata.toJSON(message.analyticsMetadata);
    }
    if (message.isInPartnershipDenialServiceRegions === true) {
      obj.isInPartnershipDenialServiceRegions = message.isInPartnershipDenialServiceRegions;
    }
    if (message.denialPartnerIdentifier !== "") {
      obj.denialPartnerIdentifier = message.denialPartnerIdentifier;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerInput>, I>>(base?: I): SellerInput {
    return SellerInput.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerInput>, I>>(object: I): SellerInput {
    const message = createBaseSellerInput();
    message.uuid = object.uuid ?? "";
    message.addressUuid = object.addressUuid ?? "";
    message.sellerFlowUuid = object.sellerFlowUuid ?? "";
    message.channel = object.channel ?? 0;
    message.answersJson = object.answersJson ?? "";
    message.answers = (object.answers !== undefined && object.answers !== null)
      ? Answers.fromPartial(object.answers)
      : undefined;
    message.answerPrefillsJson = object.answerPrefillsJson ?? "";
    message.answerPrefills = object.answerPrefills ?? undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    message.customerUuid = object.customerUuid ?? "";
    message.sellerFlowLastCompletedSlug = object.sellerFlowLastCompletedSlug ?? "";
    message.addressVerified = object.addressVerified ?? false;
    message.addressVerifiedStatus = object.addressVerifiedStatus ?? 0;
    message.isListed = object.isListed ?? false;
    message.isAgent = object.isAgent ?? false;
    message.isTradeIn = object.isTradeIn ?? false;
    message.isTradeInBundle = object.isTradeInBundle ?? false;
    message.isTradeInDashboard = object.isTradeInDashboard ?? false;
    message.ownerVerified = object.ownerVerified ?? false;
    message.partnershipName = object.partnershipName ?? "";
    message.hasBulkValuation = object.hasBulkValuation ?? false;
    message.homeEstimateForDenials = object.homeEstimateForDenials ?? 0;
    message.homeDetails = (object.homeDetails !== undefined && object.homeDetails !== null)
      ? SellerInput_HomeDetails.fromPartial(object.homeDetails)
      : undefined;
    message.marketingChannel = object.marketingChannel ?? 0;
    message.partnership = object.partnership ?? 0;
    message.timeline = object.timeline ?? 0;
    message.productOfferings = object.productOfferings?.map((e) => ProductOffering.fromPartial(e)) || [];
    message.region = object.region ?? "";
    message.homeDetailId = object.homeDetailId ?? "";
    message.inMarketplaceTopOfFunnelExp = object.inMarketplaceTopOfFunnelExp ?? false;
    message.segments = object.segments?.map((e) => e) || [];
    message.experimentEntityId = object.experimentEntityId ?? "";
    message.partnershipReferrals = object.partnershipReferrals?.map((e) => PartnershipReferral.fromPartial(e)) || [];
    message.attributes = (object.attributes !== undefined && object.attributes !== null)
      ? Attributes.fromPartial(object.attributes)
      : undefined;
    message.homeValue = (object.homeValue !== undefined && object.homeValue !== null)
      ? HomeValue.fromPartial(object.homeValue)
      : undefined;
    message.isCompletedAt = object.isCompletedAt ?? undefined;
    message.sellerFlowName = object.sellerFlowName ?? "";
    message.pdpUrl = object.pdpUrl ?? "";
    message.analyticsMetadata = (object.analyticsMetadata !== undefined && object.analyticsMetadata !== null)
      ? SellerInput_AnalyticsMetadata.fromPartial(object.analyticsMetadata)
      : undefined;
    message.isInPartnershipDenialServiceRegions = object.isInPartnershipDenialServiceRegions ?? false;
    message.denialPartnerIdentifier = object.denialPartnerIdentifier ?? "";
    return message;
  },
};

function createBaseSellerInput_HomeDetails(): SellerInput_HomeDetails {
  return { lastListing: undefined, physicalDetails: undefined, photos: [], nearFloodZone: false };
}

export const SellerInput_HomeDetails = {
  fromJSON(object: any): SellerInput_HomeDetails {
    return {
      lastListing: isSet(object.lastListing)
        ? SellerInput_HomeDetails_ListingDetails.fromJSON(object.lastListing)
        : undefined,
      physicalDetails: isSet(object.physicalDetails)
        ? SellerInput_HomeDetails_PhysicalDetails.fromJSON(object.physicalDetails)
        : undefined,
      photos: Array.isArray(object?.photos)
        ? object.photos.map((e: any) => String(e))
        : [],
      nearFloodZone: isSet(object.nearFloodZone) ? Boolean(object.nearFloodZone) : false,
    };
  },

  toJSON(message: SellerInput_HomeDetails): unknown {
    const obj: any = {};
    if (message.lastListing !== undefined) {
      obj.lastListing = SellerInput_HomeDetails_ListingDetails.toJSON(message.lastListing);
    }
    if (message.physicalDetails !== undefined) {
      obj.physicalDetails = SellerInput_HomeDetails_PhysicalDetails.toJSON(message.physicalDetails);
    }
    if (message.photos?.length) {
      obj.photos = message.photos;
    }
    if (message.nearFloodZone === true) {
      obj.nearFloodZone = message.nearFloodZone;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerInput_HomeDetails>, I>>(base?: I): SellerInput_HomeDetails {
    return SellerInput_HomeDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerInput_HomeDetails>, I>>(object: I): SellerInput_HomeDetails {
    const message = createBaseSellerInput_HomeDetails();
    message.lastListing = (object.lastListing !== undefined && object.lastListing !== null)
      ? SellerInput_HomeDetails_ListingDetails.fromPartial(object.lastListing)
      : undefined;
    message.physicalDetails = (object.physicalDetails !== undefined && object.physicalDetails !== null)
      ? SellerInput_HomeDetails_PhysicalDetails.fromPartial(object.physicalDetails)
      : undefined;
    message.photos = object.photos?.map((e) => e) || [];
    message.nearFloodZone = object.nearFloodZone ?? false;
    return message;
  },
};

function createBaseSellerInput_HomeDetails_ListingDetails(): SellerInput_HomeDetails_ListingDetails {
  return {
    listingId: "",
    listingStatus: "",
    realtorName: "",
    realtorEmail: "",
    listDate: "",
    listPriceCents: 0,
    listingPhotos: [],
  };
}

export const SellerInput_HomeDetails_ListingDetails = {
  fromJSON(object: any): SellerInput_HomeDetails_ListingDetails {
    return {
      listingId: isSet(object.listingId) ? String(object.listingId) : "",
      listingStatus: isSet(object.listingStatus) ? String(object.listingStatus) : "",
      realtorName: isSet(object.realtorName) ? String(object.realtorName) : "",
      realtorEmail: isSet(object.realtorEmail) ? String(object.realtorEmail) : "",
      listDate: isSet(object.listDate) ? String(object.listDate) : "",
      listPriceCents: isSet(object.listPriceCents) ? Number(object.listPriceCents) : 0,
      listingPhotos: Array.isArray(object?.listingPhotos) ? object.listingPhotos.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SellerInput_HomeDetails_ListingDetails): unknown {
    const obj: any = {};
    if (message.listingId !== "") {
      obj.listingId = message.listingId;
    }
    if (message.listingStatus !== "") {
      obj.listingStatus = message.listingStatus;
    }
    if (message.realtorName !== "") {
      obj.realtorName = message.realtorName;
    }
    if (message.realtorEmail !== "") {
      obj.realtorEmail = message.realtorEmail;
    }
    if (message.listDate !== "") {
      obj.listDate = message.listDate;
    }
    if (message.listPriceCents !== 0) {
      obj.listPriceCents = Math.round(message.listPriceCents);
    }
    if (message.listingPhotos?.length) {
      obj.listingPhotos = message.listingPhotos;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerInput_HomeDetails_ListingDetails>, I>>(
    base?: I,
  ): SellerInput_HomeDetails_ListingDetails {
    return SellerInput_HomeDetails_ListingDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerInput_HomeDetails_ListingDetails>, I>>(
    object: I,
  ): SellerInput_HomeDetails_ListingDetails {
    const message = createBaseSellerInput_HomeDetails_ListingDetails();
    message.listingId = object.listingId ?? "";
    message.listingStatus = object.listingStatus ?? "";
    message.realtorName = object.realtorName ?? "";
    message.realtorEmail = object.realtorEmail ?? "";
    message.listDate = object.listDate ?? "";
    message.listPriceCents = object.listPriceCents ?? 0;
    message.listingPhotos = object.listingPhotos?.map((e) => e) || [];
    return message;
  },
};

function createBaseSellerInput_HomeDetails_PhysicalDetails(): SellerInput_HomeDetails_PhysicalDetails {
  return {
    ageRestrictedCommunity: undefined,
    basement: undefined,
    basementFinishedSqFt: undefined,
    basementSqFt: undefined,
    basementUnfinishedSqFt: undefined,
    bathrooms: undefined,
    bathroomsFull: undefined,
    bathroomsHalf: undefined,
    bedrooms: undefined,
    carportSpaces: undefined,
    distanceToFloodZone: undefined,
    dwellingStyle: undefined,
    dwellingType: undefined,
    exteriorStories: undefined,
    garageSpaces: undefined,
    hoaNames: [],
    floodZone: undefined,
    gatedCommunity: undefined,
    pool: undefined,
    primaryOwner: undefined,
    lotSqFt: undefined,
    sqFt: undefined,
    yearBuilt: undefined,
    kitchenApplianceType: undefined,
    flooringMaterials: [],
  };
}

export const SellerInput_HomeDetails_PhysicalDetails = {
  fromJSON(object: any): SellerInput_HomeDetails_PhysicalDetails {
    return {
      ageRestrictedCommunity: isSet(object.ageRestrictedCommunity) ? Boolean(object.ageRestrictedCommunity) : undefined,
      basement: isSet(object.basement) ? String(object.basement) : undefined,
      basementFinishedSqFt: isSet(object.basementFinishedSqFt) ? Number(object.basementFinishedSqFt) : undefined,
      basementSqFt: isSet(object.basementSqFt) ? Number(object.basementSqFt) : undefined,
      basementUnfinishedSqFt: isSet(object.basementUnfinishedSqFt) ? Number(object.basementUnfinishedSqFt) : undefined,
      bathrooms: isSet(object.bathrooms) ? Number(object.bathrooms) : undefined,
      bathroomsFull: isSet(object.bathroomsFull) ? Number(object.bathroomsFull) : undefined,
      bathroomsHalf: isSet(object.bathroomsHalf) ? Number(object.bathroomsHalf) : undefined,
      bedrooms: isSet(object.bedrooms) ? Number(object.bedrooms) : undefined,
      carportSpaces: isSet(object.carportSpaces) ? Number(object.carportSpaces) : undefined,
      distanceToFloodZone: isSet(object.distanceToFloodZone) ? Number(object.distanceToFloodZone) : undefined,
      dwellingStyle: isSet(object.dwellingStyle) ? String(object.dwellingStyle) : undefined,
      dwellingType: isSet(object.dwellingType) ? String(object.dwellingType) : undefined,
      exteriorStories: isSet(object.exteriorStories) ? Number(object.exteriorStories) : undefined,
      garageSpaces: isSet(object.garageSpaces) ? Number(object.garageSpaces) : undefined,
      hoaNames: Array.isArray(object?.hoaNames) ? object.hoaNames.map((e: any) => String(e)) : [],
      floodZone: isSet(object.floodZone) ? Boolean(object.floodZone) : undefined,
      gatedCommunity: isSet(object.gatedCommunity) ? Boolean(object.gatedCommunity) : undefined,
      pool: isSet(object.pool) ? Boolean(object.pool) : undefined,
      primaryOwner: isSet(object.primaryOwner) ? String(object.primaryOwner) : undefined,
      lotSqFt: isSet(object.lotSqFt) ? Number(object.lotSqFt) : undefined,
      sqFt: isSet(object.sqFt) ? Number(object.sqFt) : undefined,
      yearBuilt: isSet(object.yearBuilt) ? Number(object.yearBuilt) : undefined,
      kitchenApplianceType: isSet(object["home.kitchen.appliance_type"])
        ? String(object["home.kitchen.appliance_type"])
        : undefined,
      flooringMaterials: Array.isArray(object?.["home.flooring_materials"])
        ? object["home.flooring_materials"].map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: SellerInput_HomeDetails_PhysicalDetails): unknown {
    const obj: any = {};
    if (message.ageRestrictedCommunity !== undefined) {
      obj.ageRestrictedCommunity = message.ageRestrictedCommunity;
    }
    if (message.basement !== undefined) {
      obj.basement = message.basement;
    }
    if (message.basementFinishedSqFt !== undefined) {
      obj.basementFinishedSqFt = message.basementFinishedSqFt;
    }
    if (message.basementSqFt !== undefined) {
      obj.basementSqFt = message.basementSqFt;
    }
    if (message.basementUnfinishedSqFt !== undefined) {
      obj.basementUnfinishedSqFt = message.basementUnfinishedSqFt;
    }
    if (message.bathrooms !== undefined) {
      obj.bathrooms = message.bathrooms;
    }
    if (message.bathroomsFull !== undefined) {
      obj.bathroomsFull = message.bathroomsFull;
    }
    if (message.bathroomsHalf !== undefined) {
      obj.bathroomsHalf = message.bathroomsHalf;
    }
    if (message.bedrooms !== undefined) {
      obj.bedrooms = message.bedrooms;
    }
    if (message.carportSpaces !== undefined) {
      obj.carportSpaces = message.carportSpaces;
    }
    if (message.distanceToFloodZone !== undefined) {
      obj.distanceToFloodZone = message.distanceToFloodZone;
    }
    if (message.dwellingStyle !== undefined) {
      obj.dwellingStyle = message.dwellingStyle;
    }
    if (message.dwellingType !== undefined) {
      obj.dwellingType = message.dwellingType;
    }
    if (message.exteriorStories !== undefined) {
      obj.exteriorStories = message.exteriorStories;
    }
    if (message.garageSpaces !== undefined) {
      obj.garageSpaces = message.garageSpaces;
    }
    if (message.hoaNames?.length) {
      obj.hoaNames = message.hoaNames;
    }
    if (message.floodZone !== undefined) {
      obj.floodZone = message.floodZone;
    }
    if (message.gatedCommunity !== undefined) {
      obj.gatedCommunity = message.gatedCommunity;
    }
    if (message.pool !== undefined) {
      obj.pool = message.pool;
    }
    if (message.primaryOwner !== undefined) {
      obj.primaryOwner = message.primaryOwner;
    }
    if (message.lotSqFt !== undefined) {
      obj.lotSqFt = message.lotSqFt;
    }
    if (message.sqFt !== undefined) {
      obj.sqFt = message.sqFt;
    }
    if (message.yearBuilt !== undefined) {
      obj.yearBuilt = message.yearBuilt;
    }
    if (message.kitchenApplianceType !== undefined) {
      obj["home.kitchen.appliance_type"] = message.kitchenApplianceType;
    }
    if (message.flooringMaterials?.length) {
      obj["home.flooring_materials"] = message.flooringMaterials;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerInput_HomeDetails_PhysicalDetails>, I>>(
    base?: I,
  ): SellerInput_HomeDetails_PhysicalDetails {
    return SellerInput_HomeDetails_PhysicalDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerInput_HomeDetails_PhysicalDetails>, I>>(
    object: I,
  ): SellerInput_HomeDetails_PhysicalDetails {
    const message = createBaseSellerInput_HomeDetails_PhysicalDetails();
    message.ageRestrictedCommunity = object.ageRestrictedCommunity ?? undefined;
    message.basement = object.basement ?? undefined;
    message.basementFinishedSqFt = object.basementFinishedSqFt ?? undefined;
    message.basementSqFt = object.basementSqFt ?? undefined;
    message.basementUnfinishedSqFt = object.basementUnfinishedSqFt ?? undefined;
    message.bathrooms = object.bathrooms ?? undefined;
    message.bathroomsFull = object.bathroomsFull ?? undefined;
    message.bathroomsHalf = object.bathroomsHalf ?? undefined;
    message.bedrooms = object.bedrooms ?? undefined;
    message.carportSpaces = object.carportSpaces ?? undefined;
    message.distanceToFloodZone = object.distanceToFloodZone ?? undefined;
    message.dwellingStyle = object.dwellingStyle ?? undefined;
    message.dwellingType = object.dwellingType ?? undefined;
    message.exteriorStories = object.exteriorStories ?? undefined;
    message.garageSpaces = object.garageSpaces ?? undefined;
    message.hoaNames = object.hoaNames?.map((e) => e) || [];
    message.floodZone = object.floodZone ?? undefined;
    message.gatedCommunity = object.gatedCommunity ?? undefined;
    message.pool = object.pool ?? undefined;
    message.primaryOwner = object.primaryOwner ?? undefined;
    message.lotSqFt = object.lotSqFt ?? undefined;
    message.sqFt = object.sqFt ?? undefined;
    message.yearBuilt = object.yearBuilt ?? undefined;
    message.kitchenApplianceType = object.kitchenApplianceType ?? undefined;
    message.flooringMaterials = object.flooringMaterials?.map((e) => e) || [];
    return message;
  },
};

function createBaseSellerInput_AnalyticsMetadata(): SellerInput_AnalyticsMetadata {
  return { addressEntry: undefined };
}

export const SellerInput_AnalyticsMetadata = {
  fromJSON(object: any): SellerInput_AnalyticsMetadata {
    return {
      addressEntry: isSet(object.addressEntry)
        ? SellerInput_AnalyticsMetadata_AddressEntry.fromJSON(object.addressEntry)
        : undefined,
    };
  },

  toJSON(message: SellerInput_AnalyticsMetadata): unknown {
    const obj: any = {};
    if (message.addressEntry !== undefined) {
      obj.addressEntry = SellerInput_AnalyticsMetadata_AddressEntry.toJSON(message.addressEntry);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerInput_AnalyticsMetadata>, I>>(base?: I): SellerInput_AnalyticsMetadata {
    return SellerInput_AnalyticsMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerInput_AnalyticsMetadata>, I>>(
    object: I,
  ): SellerInput_AnalyticsMetadata {
    const message = createBaseSellerInput_AnalyticsMetadata();
    message.addressEntry = (object.addressEntry !== undefined && object.addressEntry !== null)
      ? SellerInput_AnalyticsMetadata_AddressEntry.fromPartial(object.addressEntry)
      : undefined;
    return message;
  },
};

function createBaseSellerInput_AnalyticsMetadata_AddressEntry(): SellerInput_AnalyticsMetadata_AddressEntry {
  return { product: 0, url: "" };
}

export const SellerInput_AnalyticsMetadata_AddressEntry = {
  fromJSON(object: any): SellerInput_AnalyticsMetadata_AddressEntry {
    return {
      product: isSet(object.product) ? sellerInput_AnalyticsMetadata_ProductFromJSON(object.product) : 0,
      url: isSet(object.url) ? String(object.url) : "",
    };
  },

  toJSON(message: SellerInput_AnalyticsMetadata_AddressEntry): unknown {
    const obj: any = {};
    if (message.product !== 0) {
      obj.product = sellerInput_AnalyticsMetadata_ProductToJSON(message.product);
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SellerInput_AnalyticsMetadata_AddressEntry>, I>>(
    base?: I,
  ): SellerInput_AnalyticsMetadata_AddressEntry {
    return SellerInput_AnalyticsMetadata_AddressEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SellerInput_AnalyticsMetadata_AddressEntry>, I>>(
    object: I,
  ): SellerInput_AnalyticsMetadata_AddressEntry {
    const message = createBaseSellerInput_AnalyticsMetadata_AddressEntry();
    message.product = object.product ?? 0;
    message.url = object.url ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
