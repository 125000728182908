/* eslint-disable */
import { Timestamp } from "../../../google/protobuf/timestamp";
import { AssessmentCategory } from "./categories/assessment_category";
import { AuthenticationCategory } from "./categories/authentication_category";
import { ChatCategory } from "./categories/chat_category";
import { CommunicationCategory } from "./categories/communication_category";
import { ExperimentationCategory } from "./categories/experimentation_category";
import { MarketplacePropertyCategory } from "./categories/marketplace_property_category";
import { PlatformCategory } from "./categories/platform_category";
import { RetailWebUnlockCategory } from "./categories/retail_web_unlock_category";
import { SellCategory } from "./categories/sell_category";
import { SellDirectCategory } from "./categories/sell_direct_category";
import { SellEACategory } from "./categories/sell_ea_category";
import { SellExclusivesCategory } from "./categories/sell_exclusives_category";
import { SellListCategory } from "./categories/sell_list_category";
import { SellOfferReviewCategory } from "./categories/sell_offer_review_category";
import { SellOnboardingCategory } from "./categories/sell_onboarding_category";
import { SellORVACategory } from "./categories/sell_orva_category";
import { SellVACategory } from "./categories/sell_va_category";
import { CampaignContext } from "./contexts";
import { BackendSource, EdgeSource, MobileSource, WebsiteSource } from "./sources";

export const protobufPackage = "od_protos.journey.data";

export interface InternalEventWrapper {
  id: string;
  eventWrapper?: EventWrapper | undefined;
  receivedAt?: Date | undefined;
  consentSellOrShare?: boolean | undefined;
  leadEnrichedTraits: { [key: string]: any | undefined };
  hydrated?: Hydrated | undefined;
}

export interface InternalEventWrapper_LeadEnrichedTraitsEntry {
  key: string;
  value?: any | undefined;
}

export interface EventWrapperList {
  eventWrappers: EventWrapper[];
}

/** Next ID: 27 */
export interface EventWrapper {
  timestamp?: Date | undefined;
  source?:
    | { $case: "websiteSource"; websiteSource: WebsiteSource }
    | { $case: "mobileSource"; mobileSource: MobileSource }
    | { $case: "backendSource"; backendSource: BackendSource }
    | { $case: "edgeSource"; edgeSource: EdgeSource }
    | undefined;
  /** Must have at least one of these identifiers set */
  anonymousId?: string | undefined;
  customerUuid?: string | undefined;
  campaignContext?: CampaignContext | undefined;
  category?:
    | { $case: "experimentation"; experimentation: ExperimentationCategory }
    | { $case: "authentication"; authentication: AuthenticationCategory }
    | { $case: "sellOnboarding"; sellOnboarding: SellOnboardingCategory }
    | { $case: "platform"; platform: PlatformCategory }
    | { $case: "sell"; sell: SellCategory }
    | { $case: "sellDirect"; sellDirect: SellDirectCategory }
    | { $case: "sellList"; sellList: SellListCategory }
    | { $case: "sellEa"; sellEa: SellEACategory }
    | { $case: "sellOrva"; sellOrva: SellORVACategory }
    | { $case: "sellVa"; sellVa: SellVACategory }
    | { $case: "sellExclusives"; sellExclusives: SellExclusivesCategory }
    | { $case: "chat"; chat: ChatCategory }
    | { $case: "retailWebUnlock"; retailWebUnlock: RetailWebUnlockCategory }
    | { $case: "communication"; communication: CommunicationCategory }
    | { $case: "sellOfferReview"; sellOfferReview: SellOfferReviewCategory }
    | { $case: "assessment"; assessment: AssessmentCategory }
    | { $case: "marketplaceProperty"; marketplaceProperty: MarketplacePropertyCategory }
    | undefined;
}

export interface Hydrated {
  buyerPropertyDetails?: Hydrated_BuyerPropertyDetails | undefined;
}

export interface Hydrated_BuyerPropertyDetails {
  canSelfTour?: boolean | undefined;
  city?: string | undefined;
  inventoryType: string;
  listingState?: string | undefined;
  slug?: string | undefined;
  zip?: string | undefined;
}

function createBaseInternalEventWrapper(): InternalEventWrapper {
  return {
    id: "",
    eventWrapper: undefined,
    receivedAt: undefined,
    consentSellOrShare: undefined,
    leadEnrichedTraits: {},
    hydrated: undefined,
  };
}

export const InternalEventWrapper = {
  fromJSON(object: any): InternalEventWrapper {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      eventWrapper: isSet(object.eventWrapper) ? EventWrapper.fromJSON(object.eventWrapper) : undefined,
      receivedAt: isSet(object.receivedAt) ? fromJsonTimestamp(object.receivedAt) : undefined,
      consentSellOrShare: isSet(object.consentSellOrShare) ? Boolean(object.consentSellOrShare) : undefined,
      leadEnrichedTraits: isObject(object.leadEnrichedTraits)
        ? Object.entries(object.leadEnrichedTraits).reduce<{ [key: string]: any | undefined }>((acc, [key, value]) => {
          acc[key] = value as any | undefined;
          return acc;
        }, {})
        : {},
      hydrated: isSet(object.hydrated) ? Hydrated.fromJSON(object.hydrated) : undefined,
    };
  },

  toJSON(message: InternalEventWrapper): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.eventWrapper !== undefined) {
      obj.eventWrapper = EventWrapper.toJSON(message.eventWrapper);
    }
    if (message.receivedAt !== undefined) {
      obj.receivedAt = message.receivedAt.toISOString();
    }
    if (message.consentSellOrShare !== undefined) {
      obj.consentSellOrShare = message.consentSellOrShare;
    }
    if (message.leadEnrichedTraits) {
      const entries = Object.entries(message.leadEnrichedTraits);
      if (entries.length > 0) {
        obj.leadEnrichedTraits = {};
        entries.forEach(([k, v]) => {
          obj.leadEnrichedTraits[k] = v;
        });
      }
    }
    if (message.hydrated !== undefined) {
      obj.hydrated = Hydrated.toJSON(message.hydrated);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalEventWrapper>, I>>(base?: I): InternalEventWrapper {
    return InternalEventWrapper.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InternalEventWrapper>, I>>(object: I): InternalEventWrapper {
    const message = createBaseInternalEventWrapper();
    message.id = object.id ?? "";
    message.eventWrapper = (object.eventWrapper !== undefined && object.eventWrapper !== null)
      ? EventWrapper.fromPartial(object.eventWrapper)
      : undefined;
    message.receivedAt = object.receivedAt ?? undefined;
    message.consentSellOrShare = object.consentSellOrShare ?? undefined;
    message.leadEnrichedTraits = Object.entries(object.leadEnrichedTraits ?? {}).reduce<
      { [key: string]: any | undefined }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    message.hydrated = (object.hydrated !== undefined && object.hydrated !== null)
      ? Hydrated.fromPartial(object.hydrated)
      : undefined;
    return message;
  },
};

function createBaseInternalEventWrapper_LeadEnrichedTraitsEntry(): InternalEventWrapper_LeadEnrichedTraitsEntry {
  return { key: "", value: undefined };
}

export const InternalEventWrapper_LeadEnrichedTraitsEntry = {
  fromJSON(object: any): InternalEventWrapper_LeadEnrichedTraitsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object?.value) ? object.value : undefined };
  },

  toJSON(message: InternalEventWrapper_LeadEnrichedTraitsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InternalEventWrapper_LeadEnrichedTraitsEntry>, I>>(
    base?: I,
  ): InternalEventWrapper_LeadEnrichedTraitsEntry {
    return InternalEventWrapper_LeadEnrichedTraitsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InternalEventWrapper_LeadEnrichedTraitsEntry>, I>>(
    object: I,
  ): InternalEventWrapper_LeadEnrichedTraitsEntry {
    const message = createBaseInternalEventWrapper_LeadEnrichedTraitsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? undefined;
    return message;
  },
};

function createBaseEventWrapperList(): EventWrapperList {
  return { eventWrappers: [] };
}

export const EventWrapperList = {
  fromJSON(object: any): EventWrapperList {
    return {
      eventWrappers: Array.isArray(object?.eventWrappers)
        ? object.eventWrappers.map((e: any) => EventWrapper.fromJSON(e))
        : [],
    };
  },

  toJSON(message: EventWrapperList): unknown {
    const obj: any = {};
    if (message.eventWrappers?.length) {
      obj.eventWrappers = message.eventWrappers.map((e) => EventWrapper.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventWrapperList>, I>>(base?: I): EventWrapperList {
    return EventWrapperList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventWrapperList>, I>>(object: I): EventWrapperList {
    const message = createBaseEventWrapperList();
    message.eventWrappers = object.eventWrappers?.map((e) => EventWrapper.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEventWrapper(): EventWrapper {
  return {
    timestamp: undefined,
    source: undefined,
    anonymousId: undefined,
    customerUuid: undefined,
    campaignContext: undefined,
    category: undefined,
  };
}

export const EventWrapper = {
  fromJSON(object: any): EventWrapper {
    return {
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      source: isSet(object.websiteSource)
        ? { $case: "websiteSource", websiteSource: WebsiteSource.fromJSON(object.websiteSource) }
        : isSet(object.mobileSource)
        ? { $case: "mobileSource", mobileSource: MobileSource.fromJSON(object.mobileSource) }
        : isSet(object.backendSource)
        ? { $case: "backendSource", backendSource: BackendSource.fromJSON(object.backendSource) }
        : isSet(object.edgeSource)
        ? { $case: "edgeSource", edgeSource: EdgeSource.fromJSON(object.edgeSource) }
        : undefined,
      anonymousId: isSet(object.anonymousId) ? String(object.anonymousId) : undefined,
      customerUuid: isSet(object.customerUuid) ? String(object.customerUuid) : undefined,
      campaignContext: isSet(object.campaignContext) ? CampaignContext.fromJSON(object.campaignContext) : undefined,
      category: isSet(object.experimentation)
        ? { $case: "experimentation", experimentation: ExperimentationCategory.fromJSON(object.experimentation) }
        : isSet(object.authentication)
        ? { $case: "authentication", authentication: AuthenticationCategory.fromJSON(object.authentication) }
        : isSet(object.sellOnboarding)
        ? { $case: "sellOnboarding", sellOnboarding: SellOnboardingCategory.fromJSON(object.sellOnboarding) }
        : isSet(object.platform)
        ? { $case: "platform", platform: PlatformCategory.fromJSON(object.platform) }
        : isSet(object.sell)
        ? { $case: "sell", sell: SellCategory.fromJSON(object.sell) }
        : isSet(object.sellDirect)
        ? { $case: "sellDirect", sellDirect: SellDirectCategory.fromJSON(object.sellDirect) }
        : isSet(object.sellList)
        ? { $case: "sellList", sellList: SellListCategory.fromJSON(object.sellList) }
        : isSet(object.sellEa)
        ? { $case: "sellEa", sellEa: SellEACategory.fromJSON(object.sellEa) }
        : isSet(object.sellOrva)
        ? { $case: "sellOrva", sellOrva: SellORVACategory.fromJSON(object.sellOrva) }
        : isSet(object.sellVa)
        ? { $case: "sellVa", sellVa: SellVACategory.fromJSON(object.sellVa) }
        : isSet(object.sellExclusives)
        ? { $case: "sellExclusives", sellExclusives: SellExclusivesCategory.fromJSON(object.sellExclusives) }
        : isSet(object.chat)
        ? { $case: "chat", chat: ChatCategory.fromJSON(object.chat) }
        : isSet(object.retailWebUnlock)
        ? { $case: "retailWebUnlock", retailWebUnlock: RetailWebUnlockCategory.fromJSON(object.retailWebUnlock) }
        : isSet(object.communication)
        ? { $case: "communication", communication: CommunicationCategory.fromJSON(object.communication) }
        : isSet(object.sellOfferReview)
        ? { $case: "sellOfferReview", sellOfferReview: SellOfferReviewCategory.fromJSON(object.sellOfferReview) }
        : isSet(object.assessment)
        ? { $case: "assessment", assessment: AssessmentCategory.fromJSON(object.assessment) }
        : isSet(object.marketplaceProperty)
        ? {
          $case: "marketplaceProperty",
          marketplaceProperty: MarketplacePropertyCategory.fromJSON(object.marketplaceProperty),
        }
        : undefined,
    };
  },

  toJSON(message: EventWrapper): unknown {
    const obj: any = {};
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.source?.$case === "websiteSource") {
      obj.websiteSource = WebsiteSource.toJSON(message.source.websiteSource);
    }
    if (message.source?.$case === "mobileSource") {
      obj.mobileSource = MobileSource.toJSON(message.source.mobileSource);
    }
    if (message.source?.$case === "backendSource") {
      obj.backendSource = BackendSource.toJSON(message.source.backendSource);
    }
    if (message.source?.$case === "edgeSource") {
      obj.edgeSource = EdgeSource.toJSON(message.source.edgeSource);
    }
    if (message.anonymousId !== undefined) {
      obj.anonymousId = message.anonymousId;
    }
    if (message.customerUuid !== undefined) {
      obj.customerUuid = message.customerUuid;
    }
    if (message.campaignContext !== undefined) {
      obj.campaignContext = CampaignContext.toJSON(message.campaignContext);
    }
    if (message.category?.$case === "experimentation") {
      obj.experimentation = ExperimentationCategory.toJSON(message.category.experimentation);
    }
    if (message.category?.$case === "authentication") {
      obj.authentication = AuthenticationCategory.toJSON(message.category.authentication);
    }
    if (message.category?.$case === "sellOnboarding") {
      obj.sellOnboarding = SellOnboardingCategory.toJSON(message.category.sellOnboarding);
    }
    if (message.category?.$case === "platform") {
      obj.platform = PlatformCategory.toJSON(message.category.platform);
    }
    if (message.category?.$case === "sell") {
      obj.sell = SellCategory.toJSON(message.category.sell);
    }
    if (message.category?.$case === "sellDirect") {
      obj.sellDirect = SellDirectCategory.toJSON(message.category.sellDirect);
    }
    if (message.category?.$case === "sellList") {
      obj.sellList = SellListCategory.toJSON(message.category.sellList);
    }
    if (message.category?.$case === "sellEa") {
      obj.sellEa = SellEACategory.toJSON(message.category.sellEa);
    }
    if (message.category?.$case === "sellOrva") {
      obj.sellOrva = SellORVACategory.toJSON(message.category.sellOrva);
    }
    if (message.category?.$case === "sellVa") {
      obj.sellVa = SellVACategory.toJSON(message.category.sellVa);
    }
    if (message.category?.$case === "sellExclusives") {
      obj.sellExclusives = SellExclusivesCategory.toJSON(message.category.sellExclusives);
    }
    if (message.category?.$case === "chat") {
      obj.chat = ChatCategory.toJSON(message.category.chat);
    }
    if (message.category?.$case === "retailWebUnlock") {
      obj.retailWebUnlock = RetailWebUnlockCategory.toJSON(message.category.retailWebUnlock);
    }
    if (message.category?.$case === "communication") {
      obj.communication = CommunicationCategory.toJSON(message.category.communication);
    }
    if (message.category?.$case === "sellOfferReview") {
      obj.sellOfferReview = SellOfferReviewCategory.toJSON(message.category.sellOfferReview);
    }
    if (message.category?.$case === "assessment") {
      obj.assessment = AssessmentCategory.toJSON(message.category.assessment);
    }
    if (message.category?.$case === "marketplaceProperty") {
      obj.marketplaceProperty = MarketplacePropertyCategory.toJSON(message.category.marketplaceProperty);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EventWrapper>, I>>(base?: I): EventWrapper {
    return EventWrapper.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EventWrapper>, I>>(object: I): EventWrapper {
    const message = createBaseEventWrapper();
    message.timestamp = object.timestamp ?? undefined;
    if (
      object.source?.$case === "websiteSource" &&
      object.source?.websiteSource !== undefined &&
      object.source?.websiteSource !== null
    ) {
      message.source = {
        $case: "websiteSource",
        websiteSource: WebsiteSource.fromPartial(object.source.websiteSource),
      };
    }
    if (
      object.source?.$case === "mobileSource" &&
      object.source?.mobileSource !== undefined &&
      object.source?.mobileSource !== null
    ) {
      message.source = { $case: "mobileSource", mobileSource: MobileSource.fromPartial(object.source.mobileSource) };
    }
    if (
      object.source?.$case === "backendSource" &&
      object.source?.backendSource !== undefined &&
      object.source?.backendSource !== null
    ) {
      message.source = {
        $case: "backendSource",
        backendSource: BackendSource.fromPartial(object.source.backendSource),
      };
    }
    if (
      object.source?.$case === "edgeSource" &&
      object.source?.edgeSource !== undefined &&
      object.source?.edgeSource !== null
    ) {
      message.source = { $case: "edgeSource", edgeSource: EdgeSource.fromPartial(object.source.edgeSource) };
    }
    message.anonymousId = object.anonymousId ?? undefined;
    message.customerUuid = object.customerUuid ?? undefined;
    message.campaignContext = (object.campaignContext !== undefined && object.campaignContext !== null)
      ? CampaignContext.fromPartial(object.campaignContext)
      : undefined;
    if (
      object.category?.$case === "experimentation" &&
      object.category?.experimentation !== undefined &&
      object.category?.experimentation !== null
    ) {
      message.category = {
        $case: "experimentation",
        experimentation: ExperimentationCategory.fromPartial(object.category.experimentation),
      };
    }
    if (
      object.category?.$case === "authentication" &&
      object.category?.authentication !== undefined &&
      object.category?.authentication !== null
    ) {
      message.category = {
        $case: "authentication",
        authentication: AuthenticationCategory.fromPartial(object.category.authentication),
      };
    }
    if (
      object.category?.$case === "sellOnboarding" &&
      object.category?.sellOnboarding !== undefined &&
      object.category?.sellOnboarding !== null
    ) {
      message.category = {
        $case: "sellOnboarding",
        sellOnboarding: SellOnboardingCategory.fromPartial(object.category.sellOnboarding),
      };
    }
    if (
      object.category?.$case === "platform" &&
      object.category?.platform !== undefined &&
      object.category?.platform !== null
    ) {
      message.category = { $case: "platform", platform: PlatformCategory.fromPartial(object.category.platform) };
    }
    if (object.category?.$case === "sell" && object.category?.sell !== undefined && object.category?.sell !== null) {
      message.category = { $case: "sell", sell: SellCategory.fromPartial(object.category.sell) };
    }
    if (
      object.category?.$case === "sellDirect" &&
      object.category?.sellDirect !== undefined &&
      object.category?.sellDirect !== null
    ) {
      message.category = {
        $case: "sellDirect",
        sellDirect: SellDirectCategory.fromPartial(object.category.sellDirect),
      };
    }
    if (
      object.category?.$case === "sellList" &&
      object.category?.sellList !== undefined &&
      object.category?.sellList !== null
    ) {
      message.category = { $case: "sellList", sellList: SellListCategory.fromPartial(object.category.sellList) };
    }
    if (
      object.category?.$case === "sellEa" && object.category?.sellEa !== undefined && object.category?.sellEa !== null
    ) {
      message.category = { $case: "sellEa", sellEa: SellEACategory.fromPartial(object.category.sellEa) };
    }
    if (
      object.category?.$case === "sellOrva" &&
      object.category?.sellOrva !== undefined &&
      object.category?.sellOrva !== null
    ) {
      message.category = { $case: "sellOrva", sellOrva: SellORVACategory.fromPartial(object.category.sellOrva) };
    }
    if (
      object.category?.$case === "sellVa" && object.category?.sellVa !== undefined && object.category?.sellVa !== null
    ) {
      message.category = { $case: "sellVa", sellVa: SellVACategory.fromPartial(object.category.sellVa) };
    }
    if (
      object.category?.$case === "sellExclusives" &&
      object.category?.sellExclusives !== undefined &&
      object.category?.sellExclusives !== null
    ) {
      message.category = {
        $case: "sellExclusives",
        sellExclusives: SellExclusivesCategory.fromPartial(object.category.sellExclusives),
      };
    }
    if (object.category?.$case === "chat" && object.category?.chat !== undefined && object.category?.chat !== null) {
      message.category = { $case: "chat", chat: ChatCategory.fromPartial(object.category.chat) };
    }
    if (
      object.category?.$case === "retailWebUnlock" &&
      object.category?.retailWebUnlock !== undefined &&
      object.category?.retailWebUnlock !== null
    ) {
      message.category = {
        $case: "retailWebUnlock",
        retailWebUnlock: RetailWebUnlockCategory.fromPartial(object.category.retailWebUnlock),
      };
    }
    if (
      object.category?.$case === "communication" &&
      object.category?.communication !== undefined &&
      object.category?.communication !== null
    ) {
      message.category = {
        $case: "communication",
        communication: CommunicationCategory.fromPartial(object.category.communication),
      };
    }
    if (
      object.category?.$case === "sellOfferReview" &&
      object.category?.sellOfferReview !== undefined &&
      object.category?.sellOfferReview !== null
    ) {
      message.category = {
        $case: "sellOfferReview",
        sellOfferReview: SellOfferReviewCategory.fromPartial(object.category.sellOfferReview),
      };
    }
    if (
      object.category?.$case === "assessment" &&
      object.category?.assessment !== undefined &&
      object.category?.assessment !== null
    ) {
      message.category = {
        $case: "assessment",
        assessment: AssessmentCategory.fromPartial(object.category.assessment),
      };
    }
    if (
      object.category?.$case === "marketplaceProperty" &&
      object.category?.marketplaceProperty !== undefined &&
      object.category?.marketplaceProperty !== null
    ) {
      message.category = {
        $case: "marketplaceProperty",
        marketplaceProperty: MarketplacePropertyCategory.fromPartial(object.category.marketplaceProperty),
      };
    }
    return message;
  },
};

function createBaseHydrated(): Hydrated {
  return { buyerPropertyDetails: undefined };
}

export const Hydrated = {
  fromJSON(object: any): Hydrated {
    return {
      buyerPropertyDetails: isSet(object.buyerPropertyDetails)
        ? Hydrated_BuyerPropertyDetails.fromJSON(object.buyerPropertyDetails)
        : undefined,
    };
  },

  toJSON(message: Hydrated): unknown {
    const obj: any = {};
    if (message.buyerPropertyDetails !== undefined) {
      obj.buyerPropertyDetails = Hydrated_BuyerPropertyDetails.toJSON(message.buyerPropertyDetails);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Hydrated>, I>>(base?: I): Hydrated {
    return Hydrated.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Hydrated>, I>>(object: I): Hydrated {
    const message = createBaseHydrated();
    message.buyerPropertyDetails = (object.buyerPropertyDetails !== undefined && object.buyerPropertyDetails !== null)
      ? Hydrated_BuyerPropertyDetails.fromPartial(object.buyerPropertyDetails)
      : undefined;
    return message;
  },
};

function createBaseHydrated_BuyerPropertyDetails(): Hydrated_BuyerPropertyDetails {
  return {
    canSelfTour: undefined,
    city: undefined,
    inventoryType: "",
    listingState: undefined,
    slug: undefined,
    zip: undefined,
  };
}

export const Hydrated_BuyerPropertyDetails = {
  fromJSON(object: any): Hydrated_BuyerPropertyDetails {
    return {
      canSelfTour: isSet(object.canSelfTour) ? Boolean(object.canSelfTour) : undefined,
      city: isSet(object.city) ? String(object.city) : undefined,
      inventoryType: isSet(object.inventoryType) ? String(object.inventoryType) : "",
      listingState: isSet(object.listingState) ? String(object.listingState) : undefined,
      slug: isSet(object.slug) ? String(object.slug) : undefined,
      zip: isSet(object.zip) ? String(object.zip) : undefined,
    };
  },

  toJSON(message: Hydrated_BuyerPropertyDetails): unknown {
    const obj: any = {};
    if (message.canSelfTour !== undefined) {
      obj.canSelfTour = message.canSelfTour;
    }
    if (message.city !== undefined) {
      obj.city = message.city;
    }
    if (message.inventoryType !== "") {
      obj.inventoryType = message.inventoryType;
    }
    if (message.listingState !== undefined) {
      obj.listingState = message.listingState;
    }
    if (message.slug !== undefined) {
      obj.slug = message.slug;
    }
    if (message.zip !== undefined) {
      obj.zip = message.zip;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Hydrated_BuyerPropertyDetails>, I>>(base?: I): Hydrated_BuyerPropertyDetails {
    return Hydrated_BuyerPropertyDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Hydrated_BuyerPropertyDetails>, I>>(
    object: I,
  ): Hydrated_BuyerPropertyDetails {
    const message = createBaseHydrated_BuyerPropertyDetails();
    message.canSelfTour = object.canSelfTour ?? undefined;
    message.city = object.city ?? undefined;
    message.inventoryType = object.inventoryType ?? "";
    message.listingState = object.listingState ?? undefined;
    message.slug = object.slug ?? undefined;
    message.zip = object.zip ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
