/* eslint-disable */

export const protobufPackage = "od_protos.journey.data.categories";

/** Next ID: 3 */
export interface CommunicationCategory {
  event?: { $case: "smsReceived"; smsReceived: CommunicationCategory_SmsReceivedEvent } | {
    $case: "smsSent";
    smsSent: CommunicationCategory_SmsSentEvent;
  } | undefined;
}

/** when a customer receives an sms from opendoor */
export interface CommunicationCategory_SmsReceivedEvent {
  fromPhoneNumber: string;
  toPhoneNumber: string;
  conversationId: string;
  messageSid: string;
  content: string;
}

/** when a customer sends an sms to opendoor */
export interface CommunicationCategory_SmsSentEvent {
  fromPhoneNumber: string;
  toPhoneNumber: string;
  conversationId: string;
  messageSid: string;
  phoneNumberCategory: string;
  content: string;
}

function createBaseCommunicationCategory(): CommunicationCategory {
  return { event: undefined };
}

export const CommunicationCategory = {
  fromJSON(object: any): CommunicationCategory {
    return {
      event: isSet(object.smsReceived)
        ? { $case: "smsReceived", smsReceived: CommunicationCategory_SmsReceivedEvent.fromJSON(object.smsReceived) }
        : isSet(object.smsSent)
        ? { $case: "smsSent", smsSent: CommunicationCategory_SmsSentEvent.fromJSON(object.smsSent) }
        : undefined,
    };
  },

  toJSON(message: CommunicationCategory): unknown {
    const obj: any = {};
    if (message.event?.$case === "smsReceived") {
      obj.smsReceived = CommunicationCategory_SmsReceivedEvent.toJSON(message.event.smsReceived);
    }
    if (message.event?.$case === "smsSent") {
      obj.smsSent = CommunicationCategory_SmsSentEvent.toJSON(message.event.smsSent);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory>, I>>(base?: I): CommunicationCategory {
    return CommunicationCategory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory>, I>>(object: I): CommunicationCategory {
    const message = createBaseCommunicationCategory();
    if (
      object.event?.$case === "smsReceived" &&
      object.event?.smsReceived !== undefined &&
      object.event?.smsReceived !== null
    ) {
      message.event = {
        $case: "smsReceived",
        smsReceived: CommunicationCategory_SmsReceivedEvent.fromPartial(object.event.smsReceived),
      };
    }
    if (object.event?.$case === "smsSent" && object.event?.smsSent !== undefined && object.event?.smsSent !== null) {
      message.event = {
        $case: "smsSent",
        smsSent: CommunicationCategory_SmsSentEvent.fromPartial(object.event.smsSent),
      };
    }
    return message;
  },
};

function createBaseCommunicationCategory_SmsReceivedEvent(): CommunicationCategory_SmsReceivedEvent {
  return { fromPhoneNumber: "", toPhoneNumber: "", conversationId: "", messageSid: "", content: "" };
}

export const CommunicationCategory_SmsReceivedEvent = {
  fromJSON(object: any): CommunicationCategory_SmsReceivedEvent {
    return {
      fromPhoneNumber: isSet(object.fromPhoneNumber) ? String(object.fromPhoneNumber) : "",
      toPhoneNumber: isSet(object.toPhoneNumber) ? String(object.toPhoneNumber) : "",
      conversationId: isSet(object.conversationId) ? String(object.conversationId) : "",
      messageSid: isSet(object.messageSid) ? String(object.messageSid) : "",
      content: isSet(object.content) ? String(object.content) : "",
    };
  },

  toJSON(message: CommunicationCategory_SmsReceivedEvent): unknown {
    const obj: any = {};
    if (message.fromPhoneNumber !== "") {
      obj.fromPhoneNumber = message.fromPhoneNumber;
    }
    if (message.toPhoneNumber !== "") {
      obj.toPhoneNumber = message.toPhoneNumber;
    }
    if (message.conversationId !== "") {
      obj.conversationId = message.conversationId;
    }
    if (message.messageSid !== "") {
      obj.messageSid = message.messageSid;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory_SmsReceivedEvent>, I>>(
    base?: I,
  ): CommunicationCategory_SmsReceivedEvent {
    return CommunicationCategory_SmsReceivedEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory_SmsReceivedEvent>, I>>(
    object: I,
  ): CommunicationCategory_SmsReceivedEvent {
    const message = createBaseCommunicationCategory_SmsReceivedEvent();
    message.fromPhoneNumber = object.fromPhoneNumber ?? "";
    message.toPhoneNumber = object.toPhoneNumber ?? "";
    message.conversationId = object.conversationId ?? "";
    message.messageSid = object.messageSid ?? "";
    message.content = object.content ?? "";
    return message;
  },
};

function createBaseCommunicationCategory_SmsSentEvent(): CommunicationCategory_SmsSentEvent {
  return {
    fromPhoneNumber: "",
    toPhoneNumber: "",
    conversationId: "",
    messageSid: "",
    phoneNumberCategory: "",
    content: "",
  };
}

export const CommunicationCategory_SmsSentEvent = {
  fromJSON(object: any): CommunicationCategory_SmsSentEvent {
    return {
      fromPhoneNumber: isSet(object.fromPhoneNumber) ? String(object.fromPhoneNumber) : "",
      toPhoneNumber: isSet(object.toPhoneNumber) ? String(object.toPhoneNumber) : "",
      conversationId: isSet(object.conversationId) ? String(object.conversationId) : "",
      messageSid: isSet(object.messageSid) ? String(object.messageSid) : "",
      phoneNumberCategory: isSet(object.phoneNumberCategory) ? String(object.phoneNumberCategory) : "",
      content: isSet(object.content) ? String(object.content) : "",
    };
  },

  toJSON(message: CommunicationCategory_SmsSentEvent): unknown {
    const obj: any = {};
    if (message.fromPhoneNumber !== "") {
      obj.fromPhoneNumber = message.fromPhoneNumber;
    }
    if (message.toPhoneNumber !== "") {
      obj.toPhoneNumber = message.toPhoneNumber;
    }
    if (message.conversationId !== "") {
      obj.conversationId = message.conversationId;
    }
    if (message.messageSid !== "") {
      obj.messageSid = message.messageSid;
    }
    if (message.phoneNumberCategory !== "") {
      obj.phoneNumberCategory = message.phoneNumberCategory;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommunicationCategory_SmsSentEvent>, I>>(
    base?: I,
  ): CommunicationCategory_SmsSentEvent {
    return CommunicationCategory_SmsSentEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommunicationCategory_SmsSentEvent>, I>>(
    object: I,
  ): CommunicationCategory_SmsSentEvent {
    const message = createBaseCommunicationCategory_SmsSentEvent();
    message.fromPhoneNumber = object.fromPhoneNumber ?? "";
    message.toPhoneNumber = object.toPhoneNumber ?? "";
    message.conversationId = object.conversationId ?? "";
    message.messageSid = object.messageSid ?? "";
    message.phoneNumberCategory = object.phoneNumberCategory ?? "";
    message.content = object.content ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
